import React, { useState } from 'react';
import './styles.scss';
import { minutesToHours } from '../../utils/times';

export default function PeopleTable({ items, download }) {
  const [personRowsExpanded, setPersonRowsExpanded] = useState([]);
  const itemsByPerson = {};
  items.forEach((item) => {
    const personId = item.person.id;
    if (itemsByPerson[personId]) {
      itemsByPerson[personId].push(item);
    } else {
      itemsByPerson[personId] = [item];
    }
  });

  const [projectRowsExpanded, setProjectRowsExpanded] = useState(() => {
    const initialExpanded = [];
    items.forEach((item) => {
      const projectId = item.project.id;
      if (!item.objective && !initialExpanded.includes(projectId)) {
        initialExpanded.push(projectId);
      }
    });
    return initialExpanded;
  });

  const totalMinutesByPerson = {};
  Object.keys(itemsByPerson).forEach((personId) => {
    const personItems = itemsByPerson[personId];
    const totalMinutes = personItems.reduce((acc, item) => acc + item.minutes, 0);
    totalMinutesByPerson[personId] = totalMinutes;
  });

  const handleClickDetail = (personId) => {
    setPersonRowsExpanded((prevExpandedRows) => {
      if (prevExpandedRows.includes(personId)) {
        return prevExpandedRows.filter((rowId) => rowId !== personId);
      }
      return [...prevExpandedRows, personId];
    });
  };

  const handleClickProject = (projectId) => {
    setProjectRowsExpanded((prevExpandedRows) => {
      if (prevExpandedRows.includes(projectId)) {
        return prevExpandedRows.filter((rowId) => rowId !== projectId);
      }
      return [...prevExpandedRows, projectId];
    });
  };

  const groupProjectsAndObjectives = (personItems) => {
    const projects = {};
    personItems.forEach((item) => {
      const projectId = item.project.id;
      if (projects[projectId]) {
        projects[projectId].minutes += item.minutes;
        if (item.objective) {
          const objectiveId = item.objective.id;
          if (projects[projectId].objectives[objectiveId]) {
            projects[projectId].objectives[objectiveId].minutes += item.minutes;
          } else {
            projects[projectId].objectives[objectiveId] = {
              id: objectiveId,
              description: item.objective.description,
              minutes: item.minutes,
            };
          }
        }
      } else {
        projects[projectId] = {
          id: projectId,
          name: item.project.name,
          internal: item.project.internal,
          minutes: item.minutes,
          objectives: item.objective ? {
            [item.objective.id]: {
              id: item.objective.id,
              description: item.objective.description,
              minutes: item.minutes,
            },
          } : {},
        };
      }
    });
    return Object.values(projects).sort((a, b) => b.minutes - a.minutes);
  };

  function sortPeopleByTotalHours() {
    const peopleArray = Object.values(itemsByPerson);
    peopleArray.sort((personA, personB) => {
      const totalMinutesA = personA.reduce((total, item) => total + item.minutes, 0);
      const totalMinutesB = personB.reduce((total, item) => total + item.minutes, 0);
      return totalMinutesB - totalMinutesA;
    });
    return peopleArray;
  }

  return (
    <div className="worked-times-list">
      <div className="table-container">
        <div className="title-container">
          <div className="left-content">
            <div className="people-tag" />
            <h2 className="title-table">Horas cargadas por persona</h2>
          </div>
          <button type="button" onClick={download} className="btn-gral small">
            Descargar
          </button>
        </div>
        <table>
          <thead>
            <tr className="person-row">
              <th>Persona</th>
              <th>Horas</th>
              <th>Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {sortPeopleByTotalHours(itemsByPerson).map((personItems) => {
              const personId = personItems[0].person.id;
              const totalMinutes = totalMinutesByPerson[personId];

              const projects = groupProjectsAndObjectives(personItems);

              return (
                <React.Fragment key={`worked-times-item-${personId}`}>
                  <tr className="person-row">
                    <td>
                      <div className="person-cell">
                        <button
                          type="button"
                          onClick={() => handleClickDetail(personId)}
                          className="expand-button"
                        >
                          {personRowsExpanded.includes(personId) ? '-' : '+'}
                        </button>
                        <span className="person-name">{`${personItems[0].person.firstName} ${personItems[0].person.lastName}`}</span>
                      </div>
                    </td>
                    <td>
                      <span className="person-hours">{minutesToHours(totalMinutes)}</span>
                    </td>
                    <td>
                      <span className="person-average">
                        {`${((totalMinutes / (Object.values(totalMinutesByPerson)
                          .reduce((acc, minutes) => acc + minutes, 0)))
                        * 100).toFixed(2)}%`}
                      </span>
                    </td>
                  </tr>
                  {personRowsExpanded.includes(personId) && (
                    projects.map((project) => (
                      <React.Fragment key={`worked-times-project-${project.id}`}>
                        <tr className="project-row">
                          <td>
                            <div className="project-cell">
                              <div className="project-tag project-external" />
                              <button
                                type="button"
                                onClick={() => handleClickProject(project.id)}
                                className="expand-button"
                              >
                                {projectRowsExpanded.includes(project.id) ? '-' : '+'}
                              </button>
                              {project.name}
                            </div>
                          </td>
                          <td>{minutesToHours(project.minutes)}</td>
                          <td>
                            {`${((project.minutes / totalMinutes) * 100).toFixed(2)} %`}
                          </td>
                        </tr>
                        {projectRowsExpanded.includes(project.id) && (
                          Object.values(project.objectives).map((objective) => (
                            <tr key={`worked-times-objective-${objective.id}`} className="objective-row">
                              <td className="objective-cell">
                                <div className="objective-tag project-internal" />
                                <span className="objective-description">{objective.description}</span>
                              </td>
                              <td>{minutesToHours(objective.minutes)}</td>
                              <td>
                                {`${((objective.minutes / project.minutes) * 100).toFixed(2)} %`}
                              </td>
                            </tr>
                          ))
                        )}
                      </React.Fragment>
                    ))
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
