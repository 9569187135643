import React, { useState } from 'react';
import './styles.scss';
import { minutesToHours } from '../../utils/times';

const objectivesTable = ({ items }) => {
  const [expandedObjectives, setExpandedObjectives] = useState([]);
  const objectivesById = {};
  let totalMinutes = 0;
  const ObjectiveMap = {};

  items.forEach((item) => {
    const {
      objective, project, person, minutes,
    } = item;
    if (!objective) {
      return;
    }

    if (objective.id in ObjectiveMap) {
      ObjectiveMap[objective.id].totalMinutes += minutes;
      const personIndex = ObjectiveMap[objective.id].people.findIndex(
        (p) => p.id === person.id,
      );

      if (personIndex !== -1) {
        ObjectiveMap[objective.id].people[personIndex].minutes += minutes;
      } else {
        ObjectiveMap[objective.id].people.push({
          id: person.id,
          firstName: person.firstName,
          lastName: person.lastName,
          minutes,
        });
      }
    } else {
      ObjectiveMap[objective.id] = {
        id: objective.id,
        objectiveDescription: objective.description,
        projectName: project.name,
        totalMinutes: minutes,
        people: [
          {
            id: person.id,
            firstName: person.firstName,
            lastName: person.lastName,
            minutes,
          },
        ],
      };
    }
  });

  const objectiveArray = Object.values(ObjectiveMap);

  items.forEach((elem) => {
    if (!elem.objective) {
      return;
    }

    totalMinutes += elem.minutes;
    if (!objectivesById[elem.objective.id]) {
      objectivesById[elem.objective.id] = {
        id: elem.objective.id,
        description: elem.objective.description,
        projectName: elem.project.name,
        minutes: 0,
        average: 0,
      };
    }
    objectivesById[elem.objective.id].minutes += elem.minutes;
  });

  const objectives = Object.keys(objectivesById).map((id) => {
    const newElem = objectivesById[id];
    newElem.average = ((newElem.minutes * 100) / totalMinutes).toFixed(2);
    return newElem;
  }).sort((el1, el2) => {
    if (el1.projectName < el2.projectName) return -1;
    if (el1.projectName > el2.projectName) return 1;
    return el2.minutes - el1.minutes;
  });

  const handleClickDetail = (id) => {
    setExpandedObjectives((prevState) => (prevState.includes(id)
      ? prevState.filter((objectiveId) => objectiveId !== id)
      : [...prevState, id]));
  };

  return (
    <div className="worked-times-objectives-stats">
      <div className="table-container">
        <div className="title-container">
          <div className="objective-tag objective-internal" />
          <h2 className="title-table">Horas cargadas por objetivo</h2>
        </div>
        <table>
          <thead>
            <tr className="objectives-row">
              <th>Objetivo</th>
              <th>Proyecto</th>
              <th>Horas</th>
              <th>Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {objectives.map((objetivo) => (
              <React.Fragment key={objetivo.id}>
                <tr className="objectives-row">
                  <td>
                    <div className="objectives-cell">
                      <div className="objective-tag objective-internal" />
                      <button
                        type="button"
                        onClick={() => handleClickDetail(objetivo.id)}
                        className="expand-button"
                      >
                        {expandedObjectives.includes(objetivo.id) ? '-' : '+'}
                      </button>
                      <span className="objective-description">{objetivo.description}</span>
                    </div>
                  </td>
                  <td>
                    <span className="objective-details">
                      <span className="objective-details">{objetivo.projectName}</span>
                    </span>
                  </td>
                  <td>
                    <span className="objective-details">{minutesToHours(objetivo.minutes)}</span>
                  </td>
                  <td>
                    <span className="objective-details">{`${objetivo.average} %`}</span>
                  </td>
                </tr>
                {expandedObjectives.includes(objetivo.id)
                  && objectiveArray
                    .filter((objective) => objective.id === objetivo.id)
                    .map((objective) => objective.people
                      .sort((personA, personB) => personB.minutes - personA.minutes)
                      .map((person) => (
                        <tr key={person.id} className="details-row objectives-row">
                          <td className="person-name-cell">{`${person.firstName} ${person.lastName}`}</td>
                          <td> </td>
                          <td>{minutesToHours(person.minutes)}</td>
                          <td>{`${((person.minutes / objective.totalMinutes) * 100).toFixed(2)} %`}</td>
                        </tr>
                      )))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default objectivesTable;
